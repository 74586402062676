import router from './index'
import store from '@/store'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

// !配置NProgress选项
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.query.isnewpartner && to.path == '/Home/FenceDetail') {
    to.meta.activePath = "/Home/NewPartner"
  } else if (!to.query.isnewpartner && to.path == '/Home/FenceDetail'){
    to.meta.activePath = "/Home/Fence/FenceIndex"
  }
  // !导航选中
  store.commit('activeRoute', to.meta.activePath)

  NProgress.start();
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }

  const IsIE = isIE();

  if (IsIE && to.path != '/error403') {
    next('/error403');
    return false
  } else if(!to.meta.title) {
    next('/error')
  }else{
    next()
  }

  // !登录页面拦截
  if(!store.state.token) {
    if(['E3DVideoDetail', 'GraduationTest'].includes(to.name)){
      next('/Home/E3DVideo')
    // } else if(['FenceDetail'].includes(to.name)){
    //   next('/Home/Fence/FenceIndex')
    } else {
      next()
    }
  } else {
    next()
  }

})
router.afterEach((to) => {
  NProgress.done();
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
})
function isIE() { //ie?
  if (!!window.ActiveXObject || "ActiveXObject" in window)
    return true;
  else
    return false;
}