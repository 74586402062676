const getters = {
  module: state => {
    const MODULE = JSON.parse(JSON.stringify(state.Module))
    MODULE.industrys.unshift({ value: "0", text: "全部" })
    MODULE.products.unshift({ value: "0", text: "全部" })
    MODULE.types.unshift({ value: "0", text: "全部" })
    MODULE.direction.unshift({ value: "0", text: "全部" })
    MODULE.sorts = [
      { value: "0", text: "默认", list: [] },
      { value: "1", text: "热度", list: [] }
    ]
    return MODULE
  }
}

export default getters