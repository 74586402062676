import { GetUserInfo, GetSystemMessageTop4 } from "@/api/profile.js"
import { dataState, GetWxShare } from '@/api/all'
import { wxShares } from './wxShare'
import { getSelectModule } from "@/api/Home.js"
import store from '@/store'
import VueRouter from "vue-router";
import router from '@/router'
import { Toast } from 'vant';

// !数据统计接口
export function GetShareData(visitType, pageUrl, relationId) {
    // 数据统计
    const data = {
        visitType,
        gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
        url: window.location.href,
        pageUrl,
        relationId,
    };
    dataState(data).then((res) => {
        sessionStorage.setItem("gid", res.data);
    });
}
// !微信分享接口
export function GetWxShares(shareConfig) {
    let title = ''
    let desc = ''
    if (shareConfig) {
        title = shareConfig.title ? shareConfig.title : 'AVEVA 剑盟社区'
            // desc = shareConfig.desc ? shareConfig.desc: '剑指未来，联盟无界'
    } else {
        title = 'AVEVA 剑盟社区'
            // desc = '剑指未来，联盟无界'
    }
    GetWxShare().then(res => {
        wxShares(res, title, desc)
    })
}
// !站内信实时同步
export function common_msg_update() {
    if (store.state.token) {
        GetUserInfo().then(res => {
            store.dispatch('Profile', res.data)
            store.commit('channel', res.data.type)
        }).then(() => {
            GetSystemMessageTop4().then(res => {
                if (res.status == 1) {
                    store.dispatch('MsgTop4', res.data)
                }
            });
        })
    }
}

// !函数节流
var prev = Date.now();
export function debounce(fn, delay) {
    var now = Date.now()
    if (now - prev > delay) {
        fn()
        prev = Date.now()
    }
}

// !滚动到顶部
export function scrollTop() {
    let top = document.documentElement.scrollTop || document.body.scrollTop;
    // 实现滚动效果 
    const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
            clearInterval(timeTop);
        }
    }, 10);
}

// !读取cookie参数
export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            // 判断这个cookie的参数名是不是我们想要的
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// !登录获取个人信息
export function Login_GetInfoDetails(route, isntpc) {
    if (store.state.token) {
        GetUserInfo().then(res => {
            Modules()
            if (res.status === 1) {
                store.dispatch('Profile', res.data);
                localStorage.removeItem('channelType')
                store.commit('channel', res.data.type)
                    // store.commit('loginAlertState', isntpc?false:true)
            }
            if (route.query.redirectUrl) {
                router.push(route.query.redirectUrl)
            } else if (res.data.type == 1) {
                router.push('/Home/Live')
            } else {
                router.push('/')
            }
        }).then(() => {
            GetSystemMessageTop4().then(res => {
                if (res.status == 1) {
                    store.dispatch('MsgTop4', res.data);
                }
            });
        })
    }
}

// !个人中心 更新用户金币信息
export function Update_Info_gold() {
    if (store.state.token) {
        GetUserInfo().then(res => {
            if (res.status === 1) {
                store.dispatch('Profile', res.data);
            }
        })
    }
}

export function GetModules() {
    Modules()
}

// !获取模块配置
function Modules() {
    getSelectModule().then(res => {
        if (res.status == 1) {
            store.dispatch('GetModules', res.data);
            // routePath(res.data)
        } else {
            Toast(res.message)
        }
    })
}
// !导航重组
function routePath(res) {
    const route = JSON.parse(JSON.stringify(store.state.navPath))

    // * 习剑菜单
    const route1 = []
    res.products.map(item => {
        if (item.extend2 == '' || item.extend2 == '习剑') {
            route1.push({
                'title': item.text,
                'path': '/Home/Fence/FenceIndex?Id=' + item.value,
                'id': '1' + item.value
            })
        }
    })

    // * 御剑菜单
    const route2 = []
    res.products.map(item => {
        if (item.extend2 == '' || item.extend2 == '驭剑') {
            route2.push({
                'title': item.text,
                'path': '/Home/Forum?type=' + item.value,
                'id': '2' + item.value
            })
        }
    })

    route[1].items = route1
    route[2].items = route2
    store.commit('routePath', route)
}