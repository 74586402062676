import { ApiToken } from "../utils/ApiToken";
import { getCookie } from "../utils/common";

const state = {
  // !监听屏幕宽度
  clientWidth: document.body.clientWidth,
  // !token
  token: localStorage.getItem(ApiToken.token) ? localStorage.getItem(ApiToken.token) : '',
  // !默认菜单选中
  activeRoute: '/',
  // !个人信息共享
  Infos: {
    name: '',
    isPartner: '',
    type: 0,
    isGiveLive:''
  },
  // !导航
  navPath: [
    { id: '10010', title: "首页", path: "/Home/Index", items: [], },
    {
      id: '10020', title: "习剑", path: "/Home/Fence/FenceIndex", items: [],
    },
    {
      id: '10030', title: "驭剑", path: "/Home/Forum", items: [],
    },
    { id: '10040', title: "剑鸣", path: "/Home/keenMind", items: [] },
    { id: '10070', title: "营销中心", path: "/Home/MarketingCenter", items: [] },
    { id: '10050', title: "剑盟商城", path: "/Home/ShoppingMall", items: [] },
    { id: '10060', title: "合作伙伴", path: "/Home/Partner", items: [] },
  ],
  // !行业、方向、职务、工作职能 配置
  Module: {
    industrys: [],                          // !行业
    direction: [],                          // !方向
    position: [],                           // !工作职能
    scope: [],                              // !职务
    partnerType: [],
    types: [],
    products: []
  },
  // !站内信消息
  MsgTop4: [],
  Banners: {},                              // !banner 共享
  // !捕获访问渠道
  channel: localStorage.getItem('channelType') !== null ? localStorage.getItem('channelType') * 1 :  getCookie(ApiToken.channel) ? 1 : -1,
  // !邀请人 id
  inviterId: '',
  e3dinviterId: '',
  // !登录时弹窗状态
  loginAlertState: false,
}

export default state