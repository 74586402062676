import wx from 'weixin-js-sdk'
import { ApiToken } from '@/utils/ApiToken'

export function wxShares(res, title, desc) {
    wx.config(res)
    wxReady(title, desc)
}

export function wxReady(title, desc) {
    wx.ready(function() {
        const share = {
            title,
            desc,
            imgUrl: ApiToken.url + require('@/assets/PC/wxShare.png'),
            link: window.location.href,
            success: function() {
                // 分享成功，成功之后要做的事情
            },
            cancel: function() {
                // 分享取消，取消之后要做的事情
            }
        }
        wx.onMenuShareAppMessage(share) // 微信好友
        wx.onMenuShareTimeline(share) // 朋友圈
        wx.onMenuShareQQ(share) // QQ
        wx.onMenuShareQZone(share) // QQ空间
        wx.onMenuShareWeibo(share) // 腾讯微博
    })
}