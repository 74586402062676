const actions = {
  GetBanner(state, res) {
    state.commit('GetBanners', res)
  },
  // !获取
  GetModules(state, res) {
    state.commit('ChangeModules', res)
  },
  Profile(state, res) {
    state.commit('Info', res)
  },
  MsgTop4(state, res) {
    state.commit('MsgTop4s', res)
  },
  GetactivityDetails(state, res) {
    state.commit('GetactivityDetail', res)
  },
  // !学习测试完成 更新 个人信息考试字段
  SubmitExam (state, res) {
    state.commit('SubmitExam', res)
  }
}

export default actions