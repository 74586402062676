export const ApiToken = {
  //! 测试站点
  // token: 'token',
  // url: 'https://www.avevacommunity.com/test/',
  // shareUrl: 'https://www.avevacommunity.com/test/#/',
  // baseApi: 'https://www.avevacommunity.com/testapi/api',
  // channel: 'channel',
  // roadShowId: '10867',
  // trainingWx: '10876', 
  // trainingLs: '10878',
  // trainingForm: 10882,
  // roadShowForm: 10883,
  // workShopId: '10885',
  
  //! 正式站点
  token: 'token2',
  url: 'https://www.avevacommunity.com/',
  shareUrl: 'https://www.avevacommunity.com/#/',
  baseApi: 'https://www.avevacommunity.com/api/api',
  channel: 'channel_prod',
  roadShowId: '10870',
  trainingWx: '10900', 
  trainingLs: '10901',
  trainingForm: 10905,
  roadShowForm: 10906,
  workShopId: '10908',
}