import request from '@/utils/request'
// !获取当前用户信息
export function GetUserInfo(params) {
  return request({
    url: '/Personal/GetUserInfo',
    method: 'GET',
    params
  })
}

// !更新用户信息
export function UpUserInfo(data) {
  return request({
    url: '/Personal/UpUserInfo',
    method: 'POST',
    data
  })
}
// !获取默认头像
export function DefaultHeadImg() {
  return request({
    url: '/Personal/DefaultHeadImg',
    method: 'POST',
  })
}
// !保存头像
export function ChangeHeadImg(data) {
  return request({
    url: '/Personal/ChangeHeadImg',
    method: 'POST',
    data
  })
}

// !我的收藏 - 习剑
export function GetCollectionLibrary(params) {
  return request({
    url: '/Personal/GetCollectionLibrary',
    method: 'get',
    params
  })
}

// !我的收藏 - 驭剑
export function GetCollectionCommunity(params) {
  return request({
    url: '/Personal/GetCollectionCommunity',
    method: 'get',
    params
  })
}

// !我的收藏 - 剑鸣
export function GetCollectionActivity(params) {
  return request({
    url: '/Personal/GetCollectionActivity',
    method: 'get',
    params
  })
}
// !我的收藏 - 作品
export function GetCollectionActivityShow(params) {
  return request({
    url: '/Personal/GetCollectionActivityShow',
    method: 'get',
    params
  })
}

// !我的收藏 - 入门指导
export function GetCollectionIntroLearn(params) {
  return request({
    url: '/Personal/GetCollectionIntroLearn',
    method: 'get',
    params
  })
}


// !我的收藏 - Level 等级
export function GetLevelList(params) {
  return request({
    url: '/Personal/GetLevelList',
    method: 'get',
    params
  })
}

// !我的收藏 - 积分、金币记录
export function GetRecord(params) {
  return request({
    url: '/Personal/GetRecord',
    method: 'get',
    params
  })
}

// !获取勋章
export function MyMedals(params) {
  return request({
    url: '/Personal/MyMedals',
    method: 'get',
    params
  })
}

// !修改密码
export function changePwd(data) {
  return request({
    url: '/SSO/ChangePwd',
    method: 'post',
    data
  })
}
// !兑换历史记录
export function GetOrderHistory(params) {
  return request({
    url: '/Product/GetOrderHistory',
    method: 'get',
    params
  })
}
// !获取快递单号
export function GetExpressNumber(params) {
  return request({
    url: '/Product/GetExpressNumber',
    method: 'GET',
    params
  })
}

// !获取报名列表
export function GetMyActivity(params) {
  return request({
    url: '/Personal/GetMyActivity',
    method: 'GET',
    params
  })
}

// !站内信 消息
export function GetSystemMessageTop4(params) {
  return request({
    url: '/Personal/GetSystemMessageTop4',
    method: 'GET',
    params
  })
}

// !站内信 所有
export function GetSystemMessage(params) {
  return request({
    url: '/Personal/GetSystemMessage',
    method: 'GET',
    params
  })
}

// !站内信 详情
export function GetSystemMessageInfo(params) {
  return request({
    url: '/Personal/GetSystemMessageInfo',
    method: 'GET',
    params
  })
}

// !成为剑盟会员
export function UpdateUserType(data) {
  return request({
    url: '/SSO/UpdateUserType',
    method: 'post',
    data
  })
}


