import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/router/guard.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/yourSet1.scss'
import 'animate.css/animate.css'

import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';
Toast.setDefaultOptions({ duration: 1000 });
import { Icon } from 'vant';

import SvgIcon from '@/components/SvgIcon/index.vue'// svg组件
Vue.component('svg-icon', SvgIcon)
import '@/components/SvgIcon/index.js'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import lazyLoad from 'vue-lazyload'
import 'babel-polyfill'
Vue.use(lazyLoad)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false;
Vue.prototype.bus = new Vue()

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(Toast)
Vue.use(Icon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
