export default [
  {
    path: '/Home/Profile',
    name: 'Profile',
    component: () => import('@/views/Profile/Index.vue'),
    children: [
      {
        path: '/Home/Profile',
        redirect: '/Home/Profile/Information'
      },
      {
        path: '/Home/Profile/Information',
        name: 'Information',
        component: () => import('@/views/Profile/Information.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/Reset',
        name: 'Reset',
        component: () => import('@/views/Profile/reset.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/myCollect',
        name: 'myCollect',
        component: () => import('@/views/Profile/myCollect.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/MyRegistration',
        name: 'MyRegistration',
        component: () => import('@/views/Profile/MyRegistration.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/badge',
        name: 'badge',
        component: () => import('@/views/Profile/badge'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/ForRecord',
        name: 'ForRecord',
        component: () => import('@/views/Profile/ForRecord.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/DrawRecord',
        name: 'DrawRecord',
        component: () => import('@/views/Profile/DrawRecord.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
      {
        path: '/Home/Profile/taskCenter',
        name: 'taskCenter',
        component: () => import('@/views/Profile/taskCenter.vue'),
        meta: {
          title: "个人中心 | 剑盟社区",
          activePath: '/Home/Profile'
        },
      },
    ]
  },
]
