<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getSelectModule } from "@/api/Home.js"
import { GetModules } from "@/utils/common";

export default {
  name: "app",
  data() {
    return {}
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      return (() => {
        that.$store.commit('clientWidth', document.documentElement.clientWidth)
      })();
    };

    // this.GetModules()
    GetModules()
  },
  methods: {
    // // !获取模块配置
    // GetModules() {
    //   getSelectModule().then( res => {
    //     if(res.status == 1) {
    //       this.$store.dispatch('GetModules', res.data);
    //       // this.routePath(res.data)
    //     }else {
    //       this.$toast(res.message)
    //     }
    //   })
    // },
    // // !导航重组
    // routePath(res) {
    //   const route = JSON.parse(JSON.stringify( this.$store.state.navPath ))

    //   // * 习剑菜单
    //   const route1 = []
    //   res.products.map(item => {
    //     route1.push({
    //       'title': item.text,
    //       'path':'/Home/Fence/FenceIndex?Id=' + item.value,
    //       'id': '1' + item.value
    //     })
    //   })

    //   // * 御剑菜单
    //   const route2 = []
    //   res.products.map(item => {
    //     route2.push({
    //       'title': item.text,
    //       'path':'/Home/Forum?type=' + item.value,
    //       'id': '2' + item.value
    //     })
    //   })

    //   route[1].items = route1
    //   route[2].items = route2
    //   this.$store.commit('routePath', route)
    // },
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth
    }
  },
  watch: {
    clientWidth (news, olds) {
      if(this.$route.name !== 'Live') {
        if ((news < 768 && olds > 768) || (news > 768 && olds < 768)) {
          this.$toast.loading({ message: 'loading', loadingType: 'spinner' })
          setTimeout(() => {
            location.reload()
          }, 100)
        }
      }
    }
  }
};
</script>

<style lang="scss">
$mobil_base: 750;
@function mw($true) {
  @return ($true / $mobil_base) * 100vw;
}

#app { -webkit-overflow-scrolling: touch; }
.typeArea { max-width: 1200px; margin: 0 auto; }
.shade { width: 100%; height: 100vh; position: fixed; top: 0; left: 0; background: rgba(0, 0, 0, 0.6); z-index: 999999; }

//移动端
@media (max-width: 750px) {
  .typeArea { width: 100%; padding: 0 5%; margin: 0 auto; }
}
</style>
