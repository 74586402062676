import Vue from 'vue'
import VueRouter from 'vue-router'
import E3D from './module/E3D'
import Profile from './module/profile'

const Login = () =>
    import ('@/views/Login/Login.vue')
const Loginwechat = () =>
    import ('@/views/Login/Loginwechat.vue')
const LoginWXRedirect = () =>
    import ('@/views/Login/LoginWXRedirect.vue')
const LoginWXgo = () =>
    import ('@/views/Login/LoginWXgo.vue')

const FindBack = () =>
    import ('@/views/Login/FindBack.vue')
const Set = () =>
    import ('@/views/Login/Set.vue')
const Register = () =>
    import ('@/views/Register/Register.vue')
const AgreeMent = () =>
    import ('@/views/AgreeMent/index.vue')
const goldDetail = () =>
    import ('@/views/AgreeMent/goldDetail.vue')

// !驭剑
const Forum = () =>
    import ('@/views/Forum/index.vue')
const ForumSec = () =>
    import ('@/views/ForumSec/index.vue')
const Article = () =>
    import ('@/views/ForumSec/article.vue')
const Draft = () =>
    import ('@/views/ForumSec/draft.vue')
const Collect = () =>
    import ('@/views/ForumSec/collect.vue')
const Post = () =>
    import ('@/views/ForumSec/post.vue')
const Message = () =>
    import ('@/views/ForumSec/message.vue')
const ForumDetails = () =>
    import ('@/views/ForumDetails/index.vue')

// !剑鸣
const keenMind = () =>
    import ('@/views/keenMind/keenMind.vue');
const keenMindDetail = () =>
    import ('@/views/keenMind/keenMindDetail.vue');
const keenDown = () =>
    import ('@/views/keenMind/keenDown.vue');
const ActivityFile = () =>
    import ('@/views/keenMind/ActivityFile.vue');
const ActivityPreview = () =>
    import ('@/views/keenMind/ActivityPreview.vue');
const ActivitySignUp = () =>
    import ('@/views/keenMind/ActivitySignUp.vue');
const ActivityVote = () =>
    import ('@/views/keenMind/ActivityVote.vue');
const worksDetail = () =>
    import ('@/views/keenMind/worksDetail.vue');

// !剑盟商城
const ShoppingMall = () =>
    import ('@/views/ShoppingMall/Index.vue')
const ShopList = () =>
    import ('@/views/ShoppingMall/ShopList.vue')
const ShopDetail = () =>
    import ('@/views/ShoppingMall/ShopDetail.vue')
const ShopChange = () =>
    import ('@/views/ShoppingMall/ShopChange.vue')
const ShopForm = () =>
    import ('@/views/ShoppingMall/ShopForm.vue')

// !站内信
const InMail = () =>
    import ('@/views/InMail/Index.vue')
const InMailDetail = () =>
    import ('@/views/InMail/InMailDetail.vue')

// !宗派门派
const Seat = () =>
    import ('@/views/Seat/Index.vue')

// !创新大赛
const Live = () =>
    import ('@/views/Live/index.vue')
const FissionPosters = () =>
    import ('@/views/Live/FissionPosters.vue')
const E3DPoster = () =>
    import ('@/views/Fence/E3DVideo/E3DPoster/index.vue')

// !404
const error403 = () =>
    import ('@/views/Error/403.vue')
const error404 = () =>
    import ('@/views/Error/404.vue')
const error500 = () =>
    import ('@/views/Error/500.vue')

// !落地页
const landingpage = () =>
    import ('@/views/landingpage.vue')

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: "/Home"
    },
    {
        path: '/Home',
        name: 'Home',
        component: () =>
            import ('@/views/Home.vue'),
        children: [{
                path: '/Home',
                redirect: '/Home/Index'
            },
            {
                path: '/Home/Index',
                name: 'Index',
                component: () =>
                    import ('@/views/Index/index.vue'),
                meta: {
                    title: "AVEVA 剑维软件 - 剑盟社区",
                    activePath: '/Home/Index'
                },
            },
            {
                path: '/Home/Fence',
                name: 'Fence',
                component: () =>
                    import ('@/views/Fence/Fence.vue'),
                meta: {
                    title: "习剑 | 剑盟社区"
                },
                children: [{
                        path: '/Home/Fence',
                        redirect: '/Home/Fence/FenceIndex'
                    },
                    {
                        path: '/Home/Fence/FenceIndex',
                        name: 'FenceIndex',
                        component: () =>
                            import ('@/views/Fence/FenceIndex/index.vue'),
                        meta: {
                            title: "习剑 | 剑盟社区",
                            activePath: '/Home/Fence/FenceIndex'
                        }
                    },
                ]
            },
            ...E3D,
            ...Profile,
            {
                path: '/Home/FenceDetail',
                name: 'FenceDetail',
                component: () =>
                    import ('@/views/Fence/FenceDetail.vue'),
                meta: {
                    title: "习剑 | 剑盟社区",
                    activePath: '/Home/Fence/FenceIndex'
                },
            },
            {
                path: '/Home/Forum',
                name: 'Forum',
                component: Forum,
                meta: {
                    title: "驭剑 | 剑盟社区",
                    activePath: '/Home/Forum'
                }
            },
            {
                path: '/Home/ForumSec',
                name: 'ForumSec',
                component: ForumSec,
                meta: {
                    title: "驭剑 | 剑盟社区",
                    activePath: '/Home/Forum'
                },
                children: [{
                        path: '/Home/ForumSec/Article',
                        name: 'Article',
                        component: Article,
                        meta: {
                            title: "驭剑 | 剑盟社区",
                            activePath: '/Home/Forum'
                        },
                    },
                    {
                        path: '/Home/ForumSec/Draft',
                        name: 'Draft',
                        component: Draft,
                        meta: {
                            title: "驭剑 | 剑盟社区",
                            activePath: '/Home/Forum'
                        },
                    },
                    {
                        path: '/Home/ForumSec/Collect',
                        name: 'Collect',
                        component: Collect,
                        meta: {
                            title: "驭剑 | 剑盟社区",
                            activePath: '/Home/Forum'
                        },
                    },
                    {
                        path: '/Home/ForumSec/Post',
                        name: 'Post',
                        component: Post,
                        meta: {
                            title: "驭剑 | 剑盟社区",
                            activePath: '/Home/Forum'
                        },
                    },
                    {
                        path: '/Home/ForumSec/Message',
                        name: 'Message',
                        component: Message,
                        meta: {
                            title: "驭剑 | 剑盟社区",
                            activePath: '/Home/Forum'
                        },
                    }
                ]
            },
            {
                path: '/Home/ForumDetails',
                name: 'ForumDetails',
                component: ForumDetails,
                meta: {
                    title: "驭剑 | 剑盟社区",
                    activePath: '/Home/Forum'
                }
            },
            {
                path: '/Home/keenMind',
                name: 'keenMind',
                component: keenMind,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/keenMindDetail',
                name: 'keenMindDetail',
                component: keenMindDetail,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/keenDown',
                name: 'keenDown',
                component: keenDown,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/ActivityFile',
                name: 'ActivityFile',
                component: ActivityFile,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/ActivityPreview',
                name: 'ActivityPreview',
                component: ActivityPreview,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/ActivitySignUp',
                name: 'ActivitySignUp',
                component: ActivitySignUp,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/ActivityVote',
                name: 'ActivityVote',
                component: ActivityVote,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/worksDetail',
                name: 'worksDetail',
                component: worksDetail,
                meta: {
                    title: "剑鸣 | 剑盟社区",
                    activePath: '/Home/keenMind'
                },
            },
            {
                path: '/Home/InMail',
                name: 'InMail',
                component: InMail,
                meta: {
                    title: "站内信 | 剑盟社区",
                    activePath: '/Home/InMail'
                },
            },
            {
                path: '/Home/InMailDetail',
                name: 'InMailDetail',
                component: InMailDetail,
                meta: {
                    title: "站内信 | 剑盟社区",
                    activePath: '/Home/InMail'
                },
            },
            {
                path: '/Home/MarketingCenter',
                name: 'MarketingCenter',
                component: () =>
                    import ('@/views/MarketingCenter/index.vue'),
                meta: {
                    title: "营销中心 | 剑盟社区",
                    activePath: '/Home/MarketingCenter'
                }
            },
            {
                path: '/Home/ShoppingMall',
                name: 'ShoppingMall',
                component: ShoppingMall,
                meta: {
                    title: "剑盟商城 | 剑盟社区",
                    activePath: '/Home/ShoppingMall'
                },
                children: [{
                        path: '/Home/ShoppingMall',
                        redirect: '/Home/ShoppingMall/ShopList'
                    },
                    {
                        path: '/Home/ShoppingMall/ShopList',
                        name: 'ShopList',
                        component: ShopList,
                        meta: {
                            title: "剑盟商城 | 剑盟社区",
                            activePath: '/Home/ShoppingMall'
                        },
                    },
                    {
                        path: '/Home/ShoppingMall/ShopDetail',
                        name: 'ShopDetail',
                        component: ShopDetail,
                        meta: {
                            title: "剑盟商城 | 剑盟社区",
                            activePath: '/Home/ShoppingMall'
                        },
                    },
                ]
            },
            {
                path: '/Home/ShopChange',
                name: 'ShopChange',
                component: ShopChange,
                meta: {
                    title: "剑盟商城 | 剑盟社区",
                    activePath: '/Home/ShoppingMall'
                },
            },
            {
                path: '/Home/ShopForm',
                name: 'ShopForm',
                component: ShopForm,
                meta: {
                    title: "剑盟商城 | 剑盟社区",
                    activePath: '/Home/ShoppingMall'
                },
            },
            {
                path: '/Home/Seat',
                name: 'Seat',
                component: Seat,
                meta: {
                    title: "剑盟宗派 | 剑盟社区",
                },
            },
            {
                path: '/Home/Live',
                name: 'Live',
                component: Live,
                meta: {
                    title: "论剑 2021",
                    activePath: '/Home/Live'
                },
            },
            {
                path: '/Home/LivePlayback',
                name: 'LivePlayback',
                component: () =>
                    import ('@/views/Live/LivePlayback/index.vue'),
                meta: {
                    title: "论剑 2021",
                    activePath: '/Home/Live'
                },
            },
            {
                path: '/Home/Integral',
                name: 'Integral',
                component: () =>
                    import ('@/views/Integral/index.vue'),
                meta: {
                    title: "我的积分 | 剑盟社区",
                    activePath: '/Home/Integral'
                },
            },
            {
                path: '/clear',
                name: 'clear',
                component: () =>
                    import ('@/views/clear/index.vue'),
                meta: {
                    title: "清除cookie",
                    activePath: '/clear'
                },
            },
            {
                path: '/Home/Partner',
                name: 'Partner',
                component: () =>
                    import ('@/views/Partner/index.vue'),
                meta: {
                    title: "合作伙伴 | 剑盟社区",
                    activePath: '/Home/Partner'
                },
            },
            {
                path: '/Home/NewPartner',
                name: 'NewPartner',
                component: () =>
                    import ('@/views/NewPartner/index.vue'),
                meta: {
                    title: "入门指导 | 剑盟社区",
                    // activePath: '/Home/Fence/FenceIndex'
                    activePath: '/Home/NewPartner'
                },
            }
        ]
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login,
        meta: {
            title: "AVEVA 登录",
            activePath: '/Login'
        },
    },
    {
        path: '/Loginwechat',
        name: 'Loginwechat',
        component: Loginwechat,
        meta: {
            title: "AVEVA 登录",
            activePath: ''
        },
    },
    {
        path: '/LoginWXRedirect',
        name: 'LoginWXRedirect',
        component: LoginWXRedirect,
        meta: {
            title: "AVEVA 登录",
            activePath: ''
        },
    },
    {
        path: '/LoginWXgo',
        name: 'LoginWXgo',
        component: LoginWXgo,
        meta: {
            title: "AVEVA 微信绑定",
            activePath: ''
        },
    },
    {
        path: '/FindBack',
        name: 'FindBack',
        component: FindBack,
        meta: {
            title: "AVEVA 找回密码",
            activePath: ''
        },
    },
    {
        path: '/Set',
        name: 'Set',
        component: Set,
        meta: {
            title: "AVEVA 设置密码",
            activePath: ''
        },
    },
    {
        path: '/Register',
        name: 'Register',
        component: Register,
        meta: {
            title: "AVEVA 注册",
            activePath: '/Register'
        },
    },
    {
        path: '/AgreeMent',
        name: 'AgreeMent',
        component: AgreeMent,
        meta: {
            title: "AVEVA 社区微站用户使用协议（剑盟协议）",
            activePath: ''
        },
    },
    {
        path: '/goldDetail',
        name: 'goldDetail',
        component: goldDetail,
        meta: {
            title: "AVEVA 社区微站金币获取途径详情",
            activePath: ''
        },
    },
    {
        path: '/error',
        name: 'error404',
        component: error404,
        meta: {
            title: "找不到页面",
            activePath: ''
        },
    },
    {
        path: '/error500',
        name: 'error500',
        component: error500,
        meta: {
            title: "找不到页面",
            activePath: ''
        },
    },
    {
        path: '/error403',
        name: 'error403',
        component: error403,
        meta: {
            title: "平台不支持浏览",
            activePath: ''
        },
    },
    {
        path: '/landingpage',
        name: 'landingpage',
        component: landingpage,
        meta: {
            title: "关于剑盟",
            activePath: ''
        }
    },
    {
        path: '/FissionPosters',
        name: 'FissionPosters',
        component: FissionPosters,
        meta: {
            title: "论剑 2021",
            activePath: ''
        }
    },
    {
        path: '/E3DPoster',
        name: 'E3DPoster',
        component: E3DPoster,
        meta: {
            title: "AVEVA E3D 推荐红人计划",
            activePath: ''
        }
    }, {
        path: '/enable',
        name: 'enable',
        component: () =>
            import ('@/views/summit/index.vue'),
        meta: {
            title: "剑维软件合作伙伴峰会",
            activePath: '/enable'
        }
    }, {
        path: '/enablesi',
        name: 'enablesi',
        component: () =>
            import ('@/views/enablesi/index.vue'),
        meta: {
            title: "剑维软件合作伙伴峰会",
            activePath: '/enablesi'
        }
    }, {
        path: '/conference',
        name: 'conference',
        component: () =>
            import ('@/views/conference/index.vue'),
        meta: {
            title: "第七届绿色厂务大会",
            activePath: '/conference'
        }
    }, {
        path: '/newenergyconference',
        name: 'newenergyconference',
        component: () =>
            import ('@/views/newenergyconference/index.vue'),
        meta: {
            title: "新能源产业数智化升级论坛",
            activePath: '/newenergyconference'
        }
    }, {
        path: '/activity',
        name: 'activity',
        component: () =>
            import ('@/views/activity/index.vue'),
        meta: {
            title: "剑指无界，共盟新程",
            activePath: '/activity'
        }
    }, {
        path: '/Roadshow',
        name: 'Roadshow',
        component: () =>
            import ('@/views/Roadshow/index.vue'),
        meta: {
            title: "剑指无界，共盟新程",
            activePath: '/Roadshow'
        }
    }, {
        path: '/Paper',
        name: 'Paper',
        component: () =>
            import ('@/views/Paper/index.vue'),
        meta: {
            title: "工业自动化先锋论文集",
            activePath: '/Paper'
        }
    },
    {
        path: '/IndustryWorkshop',
        name: 'IndustryWorkshop',
        component: () =>
            import ('@/views/activity/IndustryWorkshop.vue'),
        meta: {
            title: "融汇数字创新，释放工业动能",
            activePath: '/IndustryWorkshop'
        }
    },
    {
        path: '/RoadshowForm',
        name: 'RoadshowForm',
        component: () =>
            import ('@/views/activity/RoadshowForm.vue'),
        meta: {
            title: "融汇数字创新，释放工业动能",
            activePath: '/RoadshowForm'
        }
    },
    {
        path: '/Training-wx',
        name: 'Training-wx',
        component: () =>
            import ('@/views/activity/Training-wx.vue'),
        meta: {
            title: "2023 AVEVA 合作伙伴“超燃精英”工程师训练营",
            activePath: '/Training-wx'
        }
    },
    {
        path: '/Training-ls',
        name: 'Training-ls',
        component: () =>
            import ('@/views/activity/Training-ls.vue'),
        meta: {
            title: "2023 AVEVA 合作伙伴“超燃精英”工程师训练营",
            activePath: '/Training-ls'
        }
    },
    {
        path: '/WorkshopReview',
        name: 'WorkshopReview',
        component: () =>
            import ('@/views/activity/WorkshopReview.vue'),
        meta: {
            title: "融汇数字创新，释放工业动能",
            activePath: '/WorkshopReview'
        }
    },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes,
    linkActiveClass: "active"
})

export default router