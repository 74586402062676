import { ApiToken } from "../utils/ApiToken";

const mutations = {
  $_setStorage(state, res) {
    state.token = res;
    if(res) {
      localStorage.setItem(ApiToken.token, res);
    } else {
      localStorage.removeItem(ApiToken.token)
    }
  },
  ChangeModules(state, res) {
    state.Module = res;
  },
  clientWidth(state, res) {
    state.clientWidth = res;
  },
  Info(state, res) {
    state.Infos = res;
  },
  MsgTop4s(state, res) {
    state.MsgTop4 = res;
  },
  GetBanners(state, res) {
    state.Banners = res;
  },
  GetactivityDetail(state, res) {
    state.activityDetail = res;
  },
  // !导航重组
  routePath(state, res) {
    state.navPath = res;
  },
  // !默认选中
  activeRoute: (state, activeRoute) => {
    state.activeRoute = activeRoute
  },
  // !判断渠道账户权限
  channel(state, channel) {
    state.channel = channel
  },
  // !邀请人id
  inviterId(state, inviterId) {
    state.inviterId = inviterId
  },// !邀请人id
  e3dinviterId(state, e3dinviterId) {
    state.e3dinviterId = e3dinviterId
  },
  // !登录拦截弹窗状态
  loginAlertState(state, loginAlertState) {
    state.loginAlertState = loginAlertState
  },
  // !学习测试完成 更新 个人信息接口 考试字段
  SubmitExam(state, submitExamState) {
    state.Infos.isSubmitExam = submitExamState
  }
}

export default mutations;