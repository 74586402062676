import axios from "axios";
import store from '@/store'
import router from '@/router'
import { Toast } from 'vant';
import { ApiToken } from '@/utils/ApiToken'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

const service = axios.create({
	baseURL: ApiToken.baseApi,
	timeout: 30000,
})

service.interceptors.request.use(
  config =>{
    if(store.state.token){
      config.headers.common['Authorization'] ="Bearer " + store.state.token
		}
		return config;
  },
  error =>{
    //对请求错误做什么
		return Promise.reject(error);
  }
)

service.interceptors.response.use(
	response => {
		if(response.status == 200) {
			return response.data;
		} else {
			return
		}
	},
	error => {
		if (error.response) {
			switch (error.response.status) {
				case 404:
					// router.push({
					// 	path: '/error'
					// })
					break;
				case 401:
					localStorage.removeItem('token');
					localStorage.removeItem('token2');
					store.state.token = '';
					Toast('您当前未登录或者登录信息已过期，请重新登录！');
					setTimeout(() => {
						router.push({
							path: '/Login'
						})
						return false;
					}, 200);
					break;
				case 402:
					localStorage.removeItem('token');
					localStorage.removeItem('token2');
					store.state.token = '';
					Toast('用户信息异常！');
					setTimeout(() => {
						router.push({
							path: '/Login'
						})
						return false;
					}, 1000);
					break;
				case 500:
					if(router.history.current.path != '/error500'){
						localStorage.removeItem('token');
						localStorage.removeItem('token2');
						router.push({
							path: '/error500'
						})
						return false
					}
					break;
				case 502:
					if(router.history.current.path != '/error500'){
						localStorage.removeItem('token');
						localStorage.removeItem('token2');
						router.push({
							path: '/error500'
						})
						return false
					}
					break
			}
		}else {
			// localStorage.removeItem('token');
			// localStorage.removeItem('token2');
			// router.push({
			// 	path: '/error500'
			// });
			return false
		}
	}
)


export default service 
