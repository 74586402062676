import request from '@/utils/request'

// !数据统计
export function dataState (data) {
  return request({
    url: '/SiteTrack/VisitLogs',
    method: 'post',
    data
  })
}

// !留言
export function AddMessage (data) {
  return request({
    url: '/Personal/AddMessage',
    method: 'post',
    data
  })
}

// !微信分享
export function GetWxShare () {
  return request({
    url: 'https://mic.anruicloud.com/wcp/api/jsticket',
    method: 'get',
    params: {
      wechatId: 'f9b6f49b-0c5d-47e3-a463-6bd568afea26',
      url: window.location.href.split('#')[0]
    }
  })
}