export default [
  // E3D视频列表
  {
    path: '/Home/E3DVideo',
    name: 'E3DVideo',
    component: () => import('@/views/Fence/E3DVideo/index.vue'),
    meta: {
      title: "习剑 | 剑盟社区",
      activePath: '/Home/Fence/FenceIndex',
    },
  },
  // E3D视频详情
  {
    path: '/Home/E3DVideoDetail',
    name: 'E3DVideoDetail',
    component: () => import('@/views/Fence/E3DVideo/E3DVideoDetail/index.vue'),
    meta: {
      title: "习剑 | 剑盟社区",
      activePath: '/Home/Fence/FenceIndex',
    },
  },
  // E3D学习测试
  {
    path: '/Home/GraduationTest',
    name: 'GraduationTest',
    component: () => import('@/views/Fence/E3DVideo/GraduationTest/index.vue'),
    meta: {
      title: "学习测试 | 剑盟社区",
      activePath: '/Home/Fence/FenceIndex'
    },
  },// E3D学习测试详情
  {
    path: '/Home/GraduationTestDetails',
    name: 'GraduationTestDetails',
    component: () => import('@/views/Fence/E3DVideo/GraduationTestDetails/index.vue'),
    meta: {
      title: "学习测试 | 剑盟社区",
      activePath: '/Home/Fence/FenceIndex'
    },
  },
]