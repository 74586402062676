import request from '@/utils/request'

// !获取首页模块
export function getHomemodule() {
  return request({
    url: '/Home/GetHomeModule',
    method: 'GET',
  })
}
// !获取产品-类型-行业
export function getSelectModule() {
  return request({
    url: '/Home/GetSelectModule',
    method: 'GET',
  })
}
// !获取banner信息
export function GetBanner() {
  return request({
    url: '/Home/GetBanner',
    method: 'GET',
  })
}
// !上传文件
export function UploadAzureBlob(data) {
  return request({
    url: '/Files/UploadAzureBlob',
    method: 'post',
    data
  })
}
// !落地页接口
export function GetLandingPage(params) {
  return request({
    url: '/LandingPage/GetLandingPage',
    method: 'GET',
    params
  })
}

// !获取活动跳转链接
export function GetApiConfig(params) {
  return request({
    url: '/Home/GetApiConfig',
    method: 'GET',
    params
  })
}

// !获取课程排行
export function GetRank(params) {
  return request({
    url: '/Home/GetStudyLibraryTop',
    method: 'GET',
    params
  })
}

// !获取公司排行
export function GetRankCompany(params) {
  return request({
    url: '/Home/GetStudyCompanyTop',
    method: 'GET',
    params
  })
}

// !获取习剑
export function GetFence(params) {
  return request({
    url: '/Home/GetHomeLibraryTop',
    method: 'GET',
    params
  })
}

// !获取论剑
export function GetForum(params) {
  return request({
    url: '/Home/GetHomeForumTop',
    method: 'GET',
    params
  })
}

// !获取公司列表
export function GetCompanyList(params) {
  return request({
    url: '/Home/GetCompanyList',
    method: 'GET',
    params
  })
}

// !获取产品-类型-行业
export function GetTreeSelect(params) {
  return request({
    url: '/Home/GetSelect',
    method: 'GET',
    params
  })
}